import React, { useContext } from 'react'

import Intro from './intro/intro'
import Settings from './settings/settings'

import { MenuContext } from '../../context/context'

import { ReactComponent as PenImage } from '../../assets/pen.svg'
import { ReactComponent as GearImage } from '../../assets/gear.svg'

import './menu.scss'

const Menu = ({expanded, reference}) => {
    const [menuState, setMenuState] = useContext(MenuContext)

    const handleMenuClick = (item) => {
        if (menuState.page !== item) {
            setMenuState({...menuState, page: item})
        }
    }

    return (
        <div className={"menu" + (expanded ? ' expanded' : '')} ref={reference}>
            <ul>
                <li onClick={() => handleMenuClick('intro')} className={menuState.page === 'intro' ? 'active' : undefined }>
                    <PenImage />
                </li>

                <li onClick={() => handleMenuClick('settings')} className={menuState.page === 'settings' ? 'active' : undefined }>
                    <GearImage />
                </li>
            </ul>

            <div className='context'>
                {menuState.page === 'intro' ? <Intro /> : null}
                {menuState.page === 'settings' ? <Settings /> : null}
            </div>
        </div>
    )
}
    

export default Menu