import React, { useContext } from "react"

import { SettingsContext } from '../../../../context/context'

import './togglebutton.scss'

const ToggleButton = ({ setting }) => {
    const [buttonState, setButtonState] = useContext(SettingsContext)
    const handleClick = () => {
        setButtonState({...buttonState, [setting]: !buttonState[setting]})
    }

    return (
        <button onClick={() => handleClick()} className={'toggle' + (buttonState[setting] ? ' enabled' : '')} />
    )
}

export default ToggleButton