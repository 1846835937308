import React, { useContext, useEffect, useState } from 'react'

import GameList from './gamelist/gamelist'
import SearchBar from './searchbar/searchbar'
import Filter from './filter/filter'
import AdditionalInfo from './additionalinfo/additionalinfo'

import { NesGamesContext } from '../../context/context'

import { ReactComponent as NesControllerImage } from '../../assets/nes.svg'

import './nes.scss'

const Nes = () => {
    const [, setData] = useContext(NesGamesContext)
    const [isLoaded, setIsLoaded] = useState(false)
    const [totalPrice, setTotalPrice] = useState()

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetch("/games.json")
            const games = await data.json()
            setData(games)

            let tempPrice = 0
            for (let item = 0; item < games.length; ++item) {
                if (Number.isInteger(games[item].cost)) tempPrice += games[item].cost
            }

            setTotalPrice(tempPrice)
            setIsLoaded(true)
        }

        fetchData()
    }, [setData])

    if (isLoaded) {
        return (
            <div className="nesgames">
                <div className="title">
                    <h1><NesControllerImage stroke='#b9daeb' />Nintendo Entertainment System cartridge collection</h1>
                    <SearchBar />
                </div>

                <Filter />

                <GameList />

                <AdditionalInfo totalPrice={totalPrice} />
            </div >
        )
    } else {
        return ""
    }
}

export default Nes