import React, { useEffect, useState, useRef, useCallback, useContext } from 'react'

import ShortcutSettings from '../../components/menu/shortcuts/shortcutsettings'

import { SettingsContext } from '../../context/context'

import BingSound from '../../assets/bing.ogg'
import BellSound from '../../assets/bell.ogg'

import './numpad.scss'

const Numpad = () => {
    const [options] = useState({
        startTime: 5000,
        interest: 300,
        ceiling: 4
    })

    const [settings] = useContext(SettingsContext)
    const [numbersVisible, setNumbersVisible] = useState(true)
    let elapsedTime = useRef(options.startTime)
    let ceilingTime = useRef(options.startTime * 2)
    let previousTick = useRef()
    let currentTick = useRef()
    const [isStarted, setIsStarted] = useState(false)
    const score = useRef(0)
    const bestScore = useRef(0)
    const [best, setBest] = useState(0)
    const [initialState] = useState({
        rotation: 180,
        ceiling: 360
    })

    const [game, setGame] = useState(initialState)

    const newRandom = () => Math.floor(Math.random() * 10)
    const numbers = useRef({
        rand1: newRandom(),
        rand2: newRandom(),
        rand3: newRandom()
    })

    const rollNew = useCallback(() => {
        numbers.current = {
            rand1: numbers.current.rand2,
            rand2: numbers.current.rand3,
            rand3: newRandom()
        }
    }, [])

    const bing = () => {
        let bing = new Audio(BingSound)
        bing.volume = 0.2
        bing.play()
    }

    const bell = () => {
        let bell = new Audio(BellSound)
        bell.volume = 0.2
        bell.play()
    }

    const startGame = useCallback(() => {
        setIsStarted(true)
        previousTick.current = Date.now()
    }, [])

    const endGame = useCallback(() => {
        setIsStarted(false)
        setGame(initialState)
        elapsedTime.current = options.startTime
        ceilingTime.current = options.startTime * 2
        if (score.current > bestScore.current) {
            bestScore.current = score.current
            setBest(bestScore.current)
        }

        score.current = 0
    }, [score, bestScore, initialState, options])

    const keyCorrect = useCallback(() => {
        elapsedTime.current = elapsedTime.current + options.interest
        if (elapsedTime.current > ceilingTime.current) {
            elapsedTime.current = ceilingTime.current
            if (settings.sounds) bell()
            rollNew()
            score.current = score.current + 2
        } else {
            if (settings.sounds) bing()
            rollNew()
            score.current = score.current + 1
        }
    }, [rollNew, options.interest, settings.sounds])

    const keyIncorrect = useCallback(() => {
        elapsedTime.current = elapsedTime.current - options.interest
    }, [options])

    const handleUserKeyPress = useCallback(({ key }) => {
        if (parseInt(key) === numbers.current.rand1) {
            if (!isStarted) startGame()
            keyCorrect()
        } else {
            if (isStarted) {
                keyIncorrect()
            }
        }
    }, [isStarted, startGame, keyCorrect, keyIncorrect])

    useEffect(() => {
        const keyPress = e => handleUserKeyPress(e)
        window.addEventListener("keydown", keyPress)

        return () => window.removeEventListener("keydown", keyPress)
    }, [handleUserKeyPress])

    useEffect(() => {
        if (isStarted) {
            const tickRate = 10
            const tick = () => {
                if (elapsedTime.current <= 0) {
                    clearInterval(timer)
                    endGame()
                } else {
                    currentTick.current = Date.now()
                    const variance = currentTick.current - previousTick.current

                    elapsedTime.current = elapsedTime.current - variance
                    ceilingTime.current = ceilingTime.current - variance / options.ceiling
                    setGame({
                        rotation: 360 / (options.startTime * 2) * elapsedTime.current,
                        ceiling: 360 / (options.startTime * 2) * ceilingTime.current
                    })

                    previousTick.current = currentTick.current
                }
            }

            let timer = setInterval(tick, tickRate)

            return () => {
                clearInterval(timer)
            }
        }
    }, [isStarted, options, endGame])

    return (
        <React.Fragment>
            <ShortcutSettings />
            <div className="numpad">
                <div className="border" />

                <div className="timer">
                    <div className="ceiling" style={{ transform: 'rotate(-' + game.ceiling + 'deg)' }} />
                    <div className="left50" style={elapsedTime.current < options.startTime ? { visibility: "hidden" } : { visibility: "visible" }} />
                    <div className="right50" style={elapsedTime.current < options.startTime ? { visibility: "visible" } : { visibility: "hidden" }} />
                    <div className="fill" style={{ transform: 'rotate(-' + game.rotation + 'deg)' }} />
                </div>

                <div className="display">
                    <div className={"numbers" + (!numbersVisible ? ' hide' : '')} onClick={() => setNumbersVisible(!numbersVisible)}>
                        <span className="rand1">{numbers.current.rand1}</span>
                        <div>
                            <span className="rand2">{numbers.current.rand2}</span>
                            <span className="rand3">{numbers.current.rand3}</span>
                        </div>
                    </div>

                    <div className="results">
                        {
                            best ? (
                                <span key={best} className="best">
                                    {best}
                                </span>
                            ) : null
                        }

                        <span className="current">
                            {score.current}
                        </span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Numpad