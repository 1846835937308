import React, { useContext, useRef, useEffect, useState } from 'react'

import { NesGamesContext } from '../../../context/context'
import { FilterContext } from '../../../context/context'

import './diagram.scss'

const Diagram = () => {
    const svgRef = useRef(null);
    const [svg, setSvg] = useState({ width: 0, height: 0 });
    const [tooltip, setTooltip] = useState({ x: 0, y: 0, amount: 0, spent: 0, title: '' })

    useEffect(() => {
        const resizeObs = new ResizeObserver(entries => {
            const { width, height } = entries[0].contentRect
            setSvg({ width, height: width / 2 })
        })
    
        if (svgRef.current) {
            resizeObs.observe(svgRef.current)
        }
    
        return () => {
            resizeObs.disconnect()
        }
    }, [svgRef])

    const handleMouseOver = (x, y, amount, spent, title) => {
        setTooltip({ x: Math.floor(x), y: Math.floor(y), amount, spent, title })
    }

    const addRange = (max, incr, euro) => {
        let rows = []
        let i = 0
        while (i <= max) {
            rows.push(i)
            i += incr
        }

        return rows.map((row, index) => (
            <text
                className={index % 2 === 1 ? 'odd' : ''} // Odd class is hidden with css in smaller resolutions
                key={index}
                x={euro ? '0' : svg.width - xPadding}
                y={(svg.width / 2 - yPadding * 2) - index * incr / max * (svg.width / 2 - yPadding * 2) + yPadding}
            >{row}{euro ? '' : '€'}</text>
        ))
    }

    const addMissingYears = (arr) => {
        let result = [];
        let yearIndex = 0;
      
        for (let year = parseInt(arr[0][0]); year <= new Date().getFullYear(); year++) {
          if (year === parseInt(arr[yearIndex]?.[0])) {
            // Year found
            result.push(arr[yearIndex]);
            yearIndex++;
          } else {
            // Year missing
            result.push([year.toString(), [0, 0]]);
          }
        }
      
        return result;
    }

    const [data] = useContext(NesGamesContext)

    // Remove filtered games from input data

    const [filter] = useContext(FilterContext)
    const years = [...new Set(data.map(game => game.bought))]
    let result = {}

    years.forEach(year => {
        const found = data.filter(game => game.bought === year)
        
        const gamesThatYear = found.length
        const costThatYear = found.map(game => typeof game.cost === 'number' ? game.cost : 0).reduce((a, b) => a + b, 0)
        
        result[year.replace('~', '')] = [gamesThatYear, costThatYear]
    })

    result = Object.entries(result).sort(([, a], [, b]) => a - b)
    result.pop()

    const highestHaul = Math.max(...result.map(item => Math.max(...[].concat(item[1][0]))))
    const highestSpent = Math.max(...result.map(item => Math.max(...[].concat(item[1][1]))))

    result = addMissingYears(result);

    let xPadding = 40
    let yPadding = 40

    return (
        <svg ref={svgRef} className='diagram' width='100%' height={svg.height + 50} preserveAspectRatio='none' xmlns="http://www.w3.org/2000/svg" version="1.1">
            <rect
                className='amount'
                x='0'
                y='0'
                width='0'
                height='0'
            />

            <text
                className='amount'
                x='0'
                y='0'
                dominantBaseline='hanging'
            >Games acquired</text>

            <rect
                className='spent'
                x='0'
                y='0'
                width='0'
                height='0'
            />

            <text
                className='spent'
                x='0'
                y='0'
                dominantBaseline='hanging'
            >Money spent</text>

            {addRange(highestHaul, 5, true)}
            {addRange(highestSpent, 100, false)}

            {
                result.map((year, index) => {
                    const xPos = (offset) => (svg.width - xPadding * 2) / result.length * (index - offset) + xPadding
                    const yPos = (offset, item, mod) => (svg.height - yPadding * 2) - result[index - offset][1][item] / mod * (svg.height - yPadding * 2) + yPadding

                    return (
                        <React.Fragment key={index}>
                            {index !== 0 ? <line
                                className='amount'
                                x1={xPos(1)}
                                x2={xPos(0)}
                                y1={yPos(1, 0, highestHaul)}
                                y2={yPos(0, 0, highestHaul)}
                            /> : ''}

                            {index !== 0 ? <line
                                className='spent'
                                x1={xPos(1)}
                                x2={xPos(0)}
                                y1={yPos(1, 1, highestSpent)}
                                y2={yPos(0, 1, highestSpent)}
                            /> : ''}

                            <line
                                className='guide'
                                x1={xPos(0)}
                                x2={xPos(0)}
                                y1='0'
                                y2={svg.width / 2}
                                strokeWidth={(svg.width - xPadding * 2 + 100) / result.length}
                                onMouseOver={() => handleMouseOver(xPos(0), yPos(0, 0, highestHaul), result[index][1][0], result[index][1][1], year[0])}
                            />

                            <line
                                className='dash'
                                x1={xPos(0)}
                                x2={xPos(0)}
                                y1={yPadding}
                                y2={svg.width / 2 - yPadding}
                            />

                            {index % 3 === 0 ? <text
                                className='rotate'
                                x={xPos(0) - 5}
                                y={svg.height - yPadding * 0.7}
                                transform={`rotate(-45 ${xPos(0) - 5} ${svg.height - yPadding * 0.7})`}
                            >{year[0]}</text> : ''}
                        </React.Fragment>
                    )
                })
            }

            <foreignObject>
                <ul className='tooltip' style={{transform: `translate(calc(${tooltip.x}px - 50%), ${svg.height - yPadding * 0.66}px)`}}>
                    <li>{tooltip.title}</li>
                    <li>{tooltip.amount}{tooltip.amount === 1 ? ' pc' : ' pcs'}</li>
                    <li>{tooltip.spent.toFixed(2)}&euro;</li>
                </ul>
            </foreignObject>
        </svg>
    )
}

export default Diagram