import React, { useState } from 'react'

import FilterMenu from '../filtermenu/filtermenu'
import FilterButton from '../filterbutton/filterbutton'

import OptionsIconOpen from '../../../assets/options_open.svg'
import OptionsIconClose from '../../../assets/options_close.svg'

import './filter.scss'

const Filter = () => {
    const [filterVisible, setFilterVisible] = useState(false)

    const toggleFiltersVisible = () => {
        setFilterVisible(!filterVisible)
    }

    return (
        <div className="filter">
            <div className={"options" + (filterVisible ? " expanded" : "")}>
                <FilterMenu title="Set">
                    <FilterButton name="cart">cartridge</FilterButton>
                    <FilterButton name="manual">manual</FilterButton>
                    <FilterButton name="box">box</FilterButton>
                </FilterMenu>

                <FilterMenu title="Cartridge type">
                    <FilterButton name="licenced">licenced</FilterButton>
                    <FilterButton name="unlicenced">unlicenced</FilterButton>
                </FilterMenu>

                <FilterMenu title="Completion status">
                    <FilterButton name="passed">finished</FilterButton>
                    <FilterButton name="unpassed">unfinished</FilterButton>
                    <FilterButton name="impossible">not attainable</FilterButton>
                </FilterMenu>
            </div>

            <div className={"options-toggle" + (filterVisible ? " flip" : "")}  onClick={toggleFiltersVisible}>
                <img src={filterVisible ? OptionsIconClose : OptionsIconOpen} alt="" />
            </div>
        </div>
    )
}

export default Filter