import React, { useContext } from "react"

import { MenuContext, HideGUIContext } from '../../../context/context'

import { ReactComponent as GearImage } from '../../../assets/gear.svg'

import './shortcutsettings.scss'

const ShortcutSettings = () => {
    const [, setMenuState] = useContext(MenuContext)
    const [hideGUI] = useContext(HideGUIContext)
        
    const openSettings = () => {
        setMenuState({expanded: true, page: 'settings'})
    }

    return (
        <div className={'openSettings' + (hideGUI ? ' hideGUI' : '')} onClick={() => openSettings()}>
            <GearImage />
        </div>
    )
}

export default ShortcutSettings