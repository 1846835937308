import React, { useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'

import { MenuContext } from '../../context/context'

import { ReactComponent as ExternalLinkImage } from '../../assets/external.svg'

import './navbutton.scss'

const NavButton = ({ blank, children, target, tooltip }) => {
    const [menuState] = useContext(MenuContext)

    return (
        <li>
            {
                blank
                ? <a className={"navbutton " + children} href={target} target="_blank" rel="noreferrer">{children}</a>
                : <NavLink className={({isActive}) => ('navbutton ' + children + (isActive ? ' current' : '' ))} to={target}>{children}</NavLink>
            }

            <span className={menuState.expanded ? 'flip' : undefined}>{tooltip}{ blank ? <ExternalLinkImage /> : '' }</span>
        </li>
    )
}

export default NavButton