import React from 'react'

import './notfound.scss'

const NotFound = () => (
    <div className="notfound">
        No such place exists.
    </div>
)

export default NotFound