import React, { useContext } from 'react'

import MenuButton from '../navbutton/menubutton/menubutton'
import NavButton from '../navbutton/navbutton'
import SubButton from '../subbutton/subbutton'

import MenuIcon from '../../assets/menu.svg'

import { HideGUIContext } from '../../context/context'

import './nav.scss'

const Nav = () => {
    const [hideGUI] = useContext(HideGUIContext)

    return (
        <nav className={hideGUI ? 'hideGUI' : undefined }>
            <ul className="nav">
                <MenuButton>
                    <img src={MenuIcon} alt="&#8199;" />
                </MenuButton>

                <li>
                    <ul className='navbuttons'>
                        <NavButton
                            target="http://steamcommunity.com/id/decldev/"
                            tooltip="Steam community profile."
                            blank
                        >
                            steam
                        </NavButton>

                        <NavButton
                            target="/nes"
                            tooltip="NES cartridge collection."
                        >
                            nes
                        </NavButton>

                        <NavButton
                            target="http://twitch.tv/decldev"
                            tooltip="Twitch.tv channel. Rarely in use."
                            blank
                        >
                            twitch
                        </NavButton>

                        <NavButton
                            target="http://naezith.com/"
                            tooltip="Remnants of Naezith, a platformer."
                            blank
                        >
                            ron
                        </NavButton>
                    </ul>
                </li>
            </ul>

            <ul className="subnav">
                <SubButton
                    target="/clicker"
                >
                    clicker
                </SubButton>

                <SubButton
                    target="/numpad"
                >
                    numpad
                </SubButton>

                <SubButton
                    target="/jumpy"
                >
                    jumpy
                </SubButton>
            </ul>
        </nav>
    )
}

export default Nav