import React from 'react'

import './filtermenu.scss'

const FilterMenu = ({ title, children }) => {
    return (
        <div>
            <h3>{title}</h3>
            <ul>
                {children.map((item, index) => {
                    return <li key={index}>{item}</li>
                })}
            </ul>
        </div>
    )
}

export default FilterMenu