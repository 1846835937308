import { createContext } from "react"

export const BackdropColorContext = createContext()

export const MenuContext = createContext()

export const SettingsContext = createContext()

export const HideGUIContext = createContext()

export const SearchContext = createContext()

export const FilterContext = createContext()

export const NesGamesContext = createContext()