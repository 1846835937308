import React, { useContext } from 'react'

import { SearchContext } from '../../../context/context'

import MagnifyingGlass from '../../../assets/search.svg'

import './searchbar.scss'

const SearchBar = () => {
    const [, setSearch] = useContext(SearchContext)

    return (
        <div className="search">
            <input type="text" onChange={e => setSearch(e.target.value)} />
            <img src={MagnifyingGlass} alt="" />
        </div>
    )
}

export default SearchBar