import React from 'react'

import Diagram from '../diagram/diagram'

import './additionalinfo.scss'

const AdditionalInfo = ({ totalPrice }) => (
    <div className="additionalinfo">
        {/*<li>I've spent a total of {totalPrice}€ during my lifetime collecting these.</li>*/}
        <Diagram />
    </div>
)

export default AdditionalInfo