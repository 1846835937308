import React, { useContext, useRef, useEffect, useCallback } from 'react'
import { Link } from "react-router-dom";

import { ReactComponent as LinkIcon } from '../../../assets/link.svg'

import { NesGamesContext } from '../../../context/context'

import './game.scss'

const Game = ({ game, index, rowClasses }) => {
    const [data, setData] = useContext(NesGamesContext)
    const anchorRef = useRef(null)
    let scrolledRef = useRef(false)

    const isCurrentAnchor = () => {
        if (anchorRef.current && window.location.href.indexOf("#") >= 0) {
            if (window.location.hash.substring(1) === anchorRef.current.id) {
                return true
            }
        }

        return false
    }

    const handleExpand = useCallback((index) => {
        // let tempData = data.slice()
        let tempData = [...data]
        //tempData.find(item => item.expanded && item.name !== name ? item.expanded = false : null) Heavy af, fix it before re-using
        tempData[index].expanded = !data[index].expanded
        setData(tempData)
    }, [data, setData])

    useEffect(() => {
        // Skip subsequent reloads after initial page load
        if (scrolledRef.current) return

        // Scroll page down to a possible anchor and expand the game
        if (isCurrentAnchor()) {
            anchorRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
            handleExpand(index)
            game.highlight = true
            scrolledRef.current = true
        }
    }, [handleExpand, game, index])

    const formatSet = string => {
        return (
            <span className={rowClasses.includes("expanded") ? "expanded" : undefined}>
                {string
                    .split(" ")
                    .map((item, index) => <span key={index}>{item}</span>)
                }
            </span>
        )
    }

    const gameSet = ({ cart, manual, styrox, box }) => {
        if (cart[0] && manual[0]) {
            if (styrox[0] && box[0]) return [
                formatSet("complete in box"),
                formatSet("complete in box"),
                formatSet("mint in box"),
                formatSet("new in box")
            ][box[1]]
            else return formatSet("loose + manual")
        }

        if (cart[0] && box[0]) return formatSet("boxed")

        return formatSet("loose")
    }

    const gameCondition = condition => {
        if (condition > -1 && condition < 4) return ["horrible", "poor", "average", "good"][condition]
        else return undefined
    }

    const gameAcquired = ({ bought, cost }) => {
        let acquired = []
        if (bought) acquired.push("in " + bought)
        if (typeof cost === "number") acquired.push(cost > 0 ? "for " + cost + "€" : "for free")
        if (acquired.length > 0) return <li>Acquired {acquired.join(" ")}</li>
        return null
    }

    const parseAnchor = (str) => {
        return str.toLowerCase().replace(/[^a-z0-9]/gi, '')
    }

    // const regex = /\w+(?:-\w+)*(?:-\d+)?-([\w/-]+(?:-\d+)?)$/;
    const cleanRegion = game.region.split('-')?.[1] !== '' ? `-${parseAnchor(game.region.split('-').pop())}` : ''
    const anchorName = `${parseAnchor(game.name)}${cleanRegion}`
    const anchorClasses = isCurrentAnchor() ? 'active' : undefined

    return (
        <tr
            className={`${rowClasses.join(" ")}${game.highlight ? ' highlight' : ''}`}
            id={anchorName}
            ref={anchorRef}
            onClick={() => handleExpand(index, game.name)}
        >
            <td>
                <div>
                    {game.region}
                    <Link className={anchorClasses} to={isCurrentAnchor() ? './' :'./#' + anchorName}>
                        <LinkIcon />
                    </Link>

                    <div className="details">
                        <ul className='info'>
                            {
                                game.released[0] ? (
                                    <li>
                                        Released in {game.released[0]}
                                        {game.released[1] ? <span className="super">{game.released[1]}</span> : null}
                                    </li>
                                ) : null
                            }
                            {gameAcquired(game)}
                            {!game.licenced ? <li><span className="pirate">Unlicensed cartridge</span></li> : null}
                        </ul>
                    </div>
                </div>
            </td>

            <td>
                <div>
                    {gameSet(game.set)}

                    <div className="details">
                        <ul className="set">
                            {game.set.cart[0] ? <li className={gameCondition(game.set.cart[1])} title={`Cartridge condition: ${gameCondition(game.set.cart[1])}`}>cart</li> : null}
                            {game.set.manual[0] ? <li className={gameCondition(game.set.manual[1])} title={`Manual condition: ${gameCondition(game.set.manual[1])}`}>manual</li> : null}
                            {game.set.styrox[0] ? <li className={gameCondition(game.set.styrox[1])} title={`Styrox condition: ${gameCondition(game.set.styrox[1])}`}>styrox</li> : null}
                            {game.set.box[0] ? <li className={gameCondition(game.set.box[1])} title={`Box condition: ${gameCondition(game.set.box[1])}`}>box</li> : null}
                        </ul>
                    </div>
                </div>
            </td>

            <td>
                {game.passed[1] ? game.passed[0] ?
                    <div className="checkmark passed" title='Game completed' />
                    : <div className="checkmark unpassed" title='Game uncompleted' />
                    : <div className="checkmark" title='Game not possible to complete' />}
            </td>

            <td>
                <div>
                    {game.name}
                </div>
            </td>
        </tr>
    )
}

export default Game