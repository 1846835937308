import React, { useCallback, useContext, useState, useEffect, useRef } from 'react'

import ShortcutSettings from '../../components/menu/shortcuts/shortcutsettings'

import useWindowDimensions from '../../hooks/useWindowDimensions'

import { HideGUIContext, SettingsContext } from '../../context/context'

import DiskSound from '../../assets/disk.ogg'

import PlayIcon from '../../assets/play.svg'

import './clicker.scss'

const Clicker = () => {
    /*const rules = [
        'After clicking the Play button, hitting the first disk will turn your cursor invisible.',
        'Timer is also started from options.startTime / 1000 seconds. Every succesful disk hit adds options.bonusTime / 1000 seconds to it.',
        'Running out of time or clicking outside any of the disks will end the game.',
        'Try fullscreen (usually F11) for better experience.'
    ]*/

    const [options, setOptions] = useState({
        disksVisible: 3, // 1-5
        startTime: 2000, // Milliseconds
        bonusTime: 300 // Milliseconds
    })

    const [hideGUI, setHideGUI] = useContext(HideGUIContext)
    const [settings] = useContext(SettingsContext)
    const [isStarted, setIsStarted] = useState(false)

    const [score, setScore] = useState(0)
    const [previousScore, setPreviousScore] = useState(0)
    const [bestScore, setBestScore] = useState(0)
    let time = useRef(options.startTime)
    let previousTick = useRef()
    let currentTick = useRef()
    const [timerBar, setTimerBar] = useState(100)

    const { height, width } = useWindowDimensions()
    const diskSize = useCallback(() => Math.floor(Math.sqrt(Math.pow(height, 2) + Math.pow(width, 2)) / 10), [height, width])
    const randomSize = useCallback(() => (Math.random() * 40 + 100), [])
    const randomX = () => Math.random() * (0.8 - 0.2) * 100
    const randomY = () => Math.random() * (0.9 - 0.1) * 100
    const randomRotation = () => Math.floor(Math.random() * 360) + 1

    const [marker, setMarker] = useState({
        x: -1000,
        y: -1000
    })

    useEffect(() => {
        const setPos = (event) => setMarker({ x: event.pageX, y: event.pageY })
        window.addEventListener("mousedown", setPos)

        return () => {
            window.removeEventListener("mousedown", setPos)
        }
    }, [])

    const initialState = useCallback(() => {
        let arr = []
        for (let disk = 0; disk < options.disksVisible + 2; ++disk) {
            arr.push({
                created: Date.now(),
                visible: (disk < options.disksVisible ? true : false),
                x: randomX(),
                y: randomY(),
                size: randomSize(),
                rotation: randomRotation()
            })
        }

        return arr
    }, [options, randomSize])

    const [diskState, setDiskState] = useState()

    const toggleGUI = useCallback(() => {
        setHideGUI(!hideGUI)
    }, [hideGUI, setHideGUI])

    const setupGame = () => {
        setDiskState(initialState)
        toggleGUI()
        setScore(0)
        setTimerBar(100)
    }

    const startGame = () => {
        setIsStarted(true)
        previousTick.current = Date.now()
    }

    const endGame = useCallback(() => {
        setIsStarted(false)
        time.current = options.startTime
        if (score > bestScore) setBestScore(score)
        setPreviousScore(score)
        toggleGUI()
    }, [bestScore, options, score, toggleGUI])

    useEffect(() => {
        if (isStarted) {
            const tickRate = 10
            const tick = () => {
                if (time.current <= 0) {
                    clearInterval(timer)
                    endGame()
                } else {
                    currentTick.current = Date.now()
                    const variance = currentTick.current - previousTick.current

                    time.current = time.current - variance
                    setTimerBar(time.current * 100 / options.startTime)

                    previousTick.current = currentTick.current
                }
            }

            let timer = setInterval(tick, tickRate)

            return () => {
                clearInterval(timer)
            }
        }
    }, [isStarted, options, endGame])

    const diskClick = (target) => {
        randomPosition()
        let newVisibility = diskState
        newVisibility[target] = {
            ...newVisibility[target],
            visible: false
        }

        if (settings.sounds) diskSound()

        if (!isStarted) {
            startGame()
        }

        let interval = time.current + options.bonusTime
        if (interval > options.startTime) interval = options.startTime
        time.current = interval
        setScore(score + 1)
    }

    const diskSound = () => {
        const audio = new Audio(DiskSound)
        audio.volume = 0.2
        audio.play()
    }

    const randomPosition = () => {
        let newPositions = diskState
        let target = newPositions.findIndex(item => item === newPositions
            .filter(item => !item.visible)
            .reduce((prev, curr) =>
                prev.created < curr.created ? prev : curr
            )
        )

        newPositions[target] = {
            ...newPositions[target],
            created: Date.now(),
            visible: true,
            x: randomX(),
            y: randomY(),
            size: randomSize(),
            rotation: randomRotation()
        }

        setDiskState(newPositions)
    }

    return (
        <React.Fragment>
            <ShortcutSettings />
            <div className="clicker">
                <div className={"setup" + (hideGUI ? ' hideGUI' : '')}>
                    <div className="dialog">
                        {bestScore > 0 ? <span key={bestScore} className="best">{bestScore}</span> : null}
                        <button className={hideGUI ? 'hideGUI' : undefined} onClick={setupGame}><img src={PlayIcon} alt="" /></button>
                        {score > 0 || bestScore > 0 ? <span className="previous-score">{previousScore}</span> : null}
                    </div>

                    <div className="range">
                        <input type="range" min="1" max="5" value={options.disksVisible} onChange={event => setOptions({ ...options, disksVisible: event.target.valueAsNumber })} />
                        <div className="steps">
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>

                    <div className={"blocks"} style={{ clipPath: 'polygon(0 0,' + options.disksVisible * 20 + '% 0,' + options.disksVisible * 20 + '% 100%, 0% 100%)' }} />
                </div>

                {
                    hideGUI ? (
                        <div className="disks" style={isStarted ? { cursor: 'none' } : undefined}>
                            {
                                diskState.map(({ visible, x, y, size, rotation }, index) => (
                                    <div
                                        key={index}
                                        className={'disk' + (!visible && !options.lowGraphics ? ' split' : '')}
                                        style={{
                                            top: x + '%',
                                            left: y + '%',
                                            width: diskSize() + 'px',
                                            height: diskSize() + 'px',
                                            transform: 'scale(' + size + '%) rotate(' + rotation + 'deg)',
                                            opacity: visible ? 1 : 0,
                                            transition: visible ? 'none' : !options.lowGraphics ? 'all .5s ease' : undefined,
                                            pointerEvents: visible ? undefined : "none",
                                            visibility: visible ? 'visible' : 'hidden'
                                        }}

                                        onMouseDown={() => diskClick(index)}
                                    >
                                        {
                                            options.lowGraphics ? <div /> : [...Array(6)].map((_, i) => <div key={i} />)
                                        }
                                    </div>
                                ))
                            }

                            {
                                <div className="hud" onMouseDown={endGame}>
                                    <div className="score">{score}</div>
                                    <div className="timer" style={{ width: timerBar + "%" }} />
                                </div>
                            }

                            {
                                isStarted ? <div className="marker" style={{ top: marker.y + 'px', left: marker.x + 'px' }} /> : null
                            }
                        </div>
                    ) : null
                }
            </div >
        </React.Fragment>
    )
}

export default Clicker