import React, { useContext, useState } from 'react'

import TableHeader from '../tableheader/tableheader'

import { NesGamesContext } from '../../../context/context'

import './sort.scss'

const Sort = () => {
    const [data, setData] = useContext(NesGamesContext)
    const [sort, setSort] = useState({
        previous: undefined,
        region: false,
        set: false,
        passed: false,
        name: false
    })

    const sortBy = category => {
        let tempData = data.slice()

        if (["region", "name"].indexOf(category) !== -1) {
            if (!sort[category] || sort.previous !== category) {
                tempData.sort((prev, curr) => {
                    let prevValue = prev[category].toUpperCase()
                    let currValue = curr[category].toUpperCase()
                    if (prevValue.toUpperCase() < currValue.toUpperCase()) return -1
                    if (prevValue.toUpperCase() > currValue.toUpperCase()) return 1
                    return 0
                })
            } else tempData.reverse()
        }

        if (category === "set") {
            if (!sort[category] && sort.previous !== category) {
                tempData.sort((prev, curr) => {
                    if (prev.set.cart[0] === curr.set.cart[0]) {
                        if (prev.set.box[0] === curr.set.box[0]) {
                            if (prev.set.manual[0] === curr.set.manual[0]) {
                                if (prev.set.box[1] === curr.set.box[1]) return 0
                                return prev.set.box[1] - curr.set.box[1]
                            }
                            return prev.set.manual[0] - curr.set.manual[0]
                        }
                        return prev.set.box[0] - curr.set.box[0]
                    }
                    return prev.set.cart[0] - curr.set.cart[0]
                })
            } else tempData.reverse()
        }

        if (category === "passed") {
            if (!sort[category] && sort.previous !== category) {
                tempData.sort((prev, curr) => {
                    if (prev.passed[1] === curr.passed[1]) {
                        if (prev.passed[0] === curr.passed[0]) return 0
                        return prev.passed[0] - curr.passed[0]
                    }
                    return prev.passed[1] - curr.passed[1]
                })
            } else tempData.reverse()
        }

        setSort({ ...sort, previous: category, [category]: !sort[category] })
        setData(tempData)
    }

    return (
        <tr className="header">
            <TableHeader name="region" sort={sort} sortBy={sortBy}>region code</TableHeader>
            <TableHeader name="set" sort={sort} sortBy={sortBy}>set</TableHeader>
            <TableHeader name="passed" sort={sort} sortBy={sortBy}>passed</TableHeader>
            <TableHeader name="name" sort={sort} sortBy={sortBy}>name</TableHeader>
        </tr>
    )
}

export default Sort