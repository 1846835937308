import React from 'react'

import './jumpy.scss'

const Jumpy = () => (
    <div className='jumpy'>
        <p>TBA</p>
    </div>
)

export default Jumpy