import React from "react"

const Intro = () => (
    <React.Fragment>
        <p>Hey! This page was previously constructed using only HTML5 &amp; CSS3 with no image files; I took it as kind of a challenge. I did however ruin that immersion by remaking whole thing with React and a few SVGs.</p>
        <p>And since I'm a perfectionist, any spotted mistakes and/or other annoyances found are gladly heard.</p>
        <p>Work in progress. Enjoy.</p>
        <span className='copy'>&copy; decldev {new Date().getFullYear()}</span>
    </React.Fragment>
)

export default Intro