import React, { useCallback, useContext, useEffect, useRef } from 'react'

import Menu from '../../menu/menu'

import { MenuContext } from '../../../context/context'

import '../navbutton.scss'

const MenuButton = ({ children }) => {
    const [menuState, setMenuState] = useContext(MenuContext)
    const menuRef = useRef(null)
    const toggleMenu = useCallback(() => {
        setMenuState({...menuState, expanded: !menuState.expanded})
    }, [menuState, setMenuState])

    useEffect(() => {
        const pageClick = (e) => {
            if (menuRef.current) {
                if (menuRef.current.contains(e.target)) return // Ignore clicks on the menu itself
                else toggleMenu()
            }
        }

        if (menuState.expanded) window.addEventListener('click', pageClick)
        return () => window.removeEventListener('click', pageClick)
    })

    return (
        <li>
            <span onClick={() => toggleMenu()} className={'navbutton menuicon' + (menuState.expanded ? ' hold' : '')}>
                {children}
            </span>

            <Menu reference={menuRef} expanded={menuState.expanded ? 'expanded' : undefined} />
        </li>
    )
}

export default MenuButton