import React from 'react'

import './tableheader.scss'

const TableHeader = ({ children, name, sort, sortBy }) => (
    <th
        className={sort.previous === name ? sort[name] ? "selected" : "reverse" : undefined}
        onClick={() => sortBy(name)}
    >
        {children}
    </th>
)

export default TableHeader