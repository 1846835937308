import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Routes as Switch } from 'react-router-dom'

import Wrapper from './components/wrapper/wrapper'
import Header from './components/header/header'
import Nav from './components/nav/nav'
import NotFound from './components/notfound/notfound'

import Nes from './pages/nes/nes'
import Clicker from './pages/clicker/clicker'
import Numpad from './pages/numpad/numpad'
import Jumpy from './pages/jumpy/tba_jumpy'

import { MenuContext, HideGUIContext, NesGamesContext, SearchContext, FilterContext, /*BackdropColorContext,*/ SettingsContext } from './context/context'

import './index.scss'

function App() {
  // const [backdropColor, setBackdropColor] = useState(0)
  const [menuState, setMenuState] = useState({expanded: false, page: 'intro'})
  const [hideGUI, setHideGUI] = useState(false)
  const [settings, setSettings] = useState({effects: true, sounds: true})
  const [search, setSearch] = useState()
  const [filter, setFilter] = useState({
    cart: true,
    manual: true,
    box: true,
    licenced: true,
    unlicenced: true,
    passed: true,
    unpassed: true,
    impossible: true
  })
  const [nesGames, setNesGames] = useState()

  return (
    //<BackdropColorContext.Provider value={[backdropColor, setBackdropColor]}>
      <MenuContext.Provider value={[menuState, setMenuState]}>
        <HideGUIContext.Provider value={[hideGUI, setHideGUI]}>
          <SettingsContext.Provider value={[settings, setSettings]}>
            <Router>
              <Wrapper>
                <Header />
                <Nav />
                <main>
                  <Switch>
                    <Route path="/nes" element={
                      <NesGamesContext.Provider value={[nesGames, setNesGames]}>
                        <SearchContext.Provider value={[search, setSearch]}>
                          <FilterContext.Provider value={[filter, setFilter]}>
                            <Nes />
                          </FilterContext.Provider>
                        </SearchContext.Provider>
                      </NesGamesContext.Provider>
                    }/>

                    <Route path="/clicker" element={
                      <Clicker />
                    }/>

                    <Route path="/numpad" element={
                      <Numpad />
                    }/>

                    <Route path="/jumpy" element={
                      <Jumpy />
                    }/>

                    <Route path="/" element={null} />

                    <Route path="*" element={
                      <NotFound />
                    }/>
                  </Switch>
                </main>
              </Wrapper>
            </Router>
          </SettingsContext.Provider>
        </HideGUIContext.Provider>
      </MenuContext.Provider>
    //</BackdropColorContext.Provider>
  )
}

ReactDOM.render(<App />, document.getElementById("root"))