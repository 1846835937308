import React, { useContext } from 'react'

import Sort from '../sort/sort'
import Game from '../game/game'

import { NesGamesContext, SearchContext, FilterContext } from '../../../context/context'

import './gamelist.scss'

const GameList = () => {
    const [filter] = useContext(FilterContext)
    const [search] = useContext(SearchContext)
    const [data] = useContext(NesGamesContext)

    let rowCounter = false

    return (
        <table>
            <tbody>
                <Sort />

                {
                    data.map((item, index) => {
                        const rowClasses = ["game"]
                        if (search && !(item.name.toLowerCase().includes(search.toLowerCase()) || item.region.toLowerCase().includes(search.toLowerCase()))) {
                            rowClasses.push("collapse")
                        } else if (
                            (!filter.cart && item.set.cart[0] && !item.set.manual[0] && !item.set.box[0]) ||
                            (!filter.manual && item.set.manual[0]) ||
                            (!filter.box && item.set.box[0]) ||
                            (!filter.licenced && item.licenced) ||
                            (!filter.unlicenced && !item.licenced) ||
                            (!filter.passed && item.passed[0]) ||
                            (!filter.unpassed && !item.passed[0] && item.passed[1]) ||
                            (!filter.impossible && !item.passed[1])
                        ) rowClasses.push("collapse")
                        else {
                            rowCounter = !rowCounter
                            if (rowCounter && !item.expanded) rowClasses.push("odd")
                            if (item.expanded) rowClasses.push("expanded")
                        }
                        
                        return <Game key={index} game={item} index={index} rowClasses={rowClasses} />
                    })
                }
            </tbody>
        </table>
    )
}

export default GameList