import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { MenuContext } from '../../context/context'

import './subbutton.scss'

const SubButton = ({ children, target }) => {
    const [menuState] = useContext(MenuContext)

    return (
        <li className={menuState.expanded ? 'fade' : undefined}>
            <NavLink className={({isActive}) => ('subbutton' + (isActive ? ' current' : '' ))} to={target}>
                <span>
                    {children}
                </span>
            </NavLink>
        </li>
    )
}

export default SubButton