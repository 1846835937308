import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'

import { HideGUIContext, SettingsContext } from '../../context/context'

// import Backdrop from '../backdrop/backdrop'
// import BackdropHueSlider from '../backdrophueslider/backdrophueslider'

import './wrapper.scss'

const Wrapper = ({ children }) => {
    const { pathname } = useLocation()
    const [hideGUI] = useContext(HideGUIContext)
    const [settings] = useContext(SettingsContext)

    return (
        <div className={'wrapper' + (pathname !== '/' ? ' top' : '') + (hideGUI ? ' hideBackground' : '') + (!settings.effects ? ' lowQuality' : '')}>
            {/*<Backdrop />
            <BackdropHueSlider />*/}
            <div className="dummy" />
            {children}
        </div>
    )
}

export default Wrapper