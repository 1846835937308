import React, { useContext } from 'react'

import { FilterContext } from '../../../context/context'

import './filterbutton.scss'

const FilterButton = ({ name, children }) => {
    const [filter, setFilter] = useContext(FilterContext)

    const changeFilter = name => {
        setFilter({ ...filter, [name]: !filter[name]})
    }

    return (
        <label className={filter[name] ? 'checked' : undefined}>
            <div className='checkmark'/>
            {children}
            <input type='checkbox' name={name} onChange={() => changeFilter(name)} />
        </label>
    )
}

export default FilterButton