import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { HideGUIContext } from '../../context/context'

import './header.scss'

const Header = () => {
    const [hideGUI] = useContext(HideGUIContext)

    return (
        <header className={hideGUI ? 'hideGUI' : undefined}>
            <Link to="/">
                <h1>decldev</h1>
            </Link>
        </header>
    )
}

export default Header