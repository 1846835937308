import React from 'react'

import ToggleButton from './togglebutton/togglebutton'

import './settings.scss'

const Settings = () => (
    <React.Fragment>
        <ul className='settings'>
            <li><span>Effects</span><ToggleButton setting='effects' /></li>
            <li><span>Game sounds</span><ToggleButton setting='sounds' /></li>
            <li><span>Color theme</span><span>soon&trade;</span></li>
        </ul>
    </React.Fragment>
)

export default Settings